const mapElement = document.getElementById("rockmount-footer-map");
const latitude = "49.4779";
const longitude = "-2.5901";

mapboxgl.accessToken =
  "pk.eyJ1IjoicXBpZGRpZ2l0YWwiLCJhIjoiY20zYnBoamljMXNwcDJtczJ3Mjh3bnJ2cyJ9.cxhh48jGQdyRfmlMGUKYjA";
const map = new mapboxgl.Map({
  container: mapElement,
  style: "mapbox://styles/mapbox/streets-v11",
  center: [longitude, latitude],
  zoom: 14,
});

const svgMarker = document.createElement("div");
svgMarker.innerHTML = `
  <svg width="42" height="64" viewBox="0 0 42 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.33,64c1.28-21.3,19.67-26.7,19.67-42.7,0-5.9-2.36-11.2-6.2-15.1-3.74-3.8-8.95-6.2-14.75-6.2S10.03,2.4,6.2,6.2C2.36,10.1,0,15.4,0,21.3c0,16,18.39,21.3,19.67,42.7h2.66Z" fill="#f7a800"/><path d="M35.58,26.32v-5.16h-.69v-4.57h-.39l-3.32-2.43v-2.14h-.9l-1.49,2.11h-1.2l-6.46-6.46-6.46,6.46h-.73l-1.49-2.11h-.9v2.59l-2.68,1.97h-.39v4.57h-.94v5.16h-2.55v3.79h14.33v-2.31h3.34v2.31h14.33v-3.79h-1.42ZM10.64,23.43h2.01v3.05h-2.01v-3.05ZM17.41,26.48h-4.32v-3.05h4.32v3.05ZM23.85,26.48h-5.2v-4.12h5.2v4.12ZM25.03,23.43h4.4v3.05h-4.4v-3.05ZM31.8,26.48h-1.93v-3.05h1.93v3.05Z" fill="#fff"/>
  </svg>
`;
svgMarker.style.width = "42px";
svgMarker.style.height = "64px";

new mapboxgl.Marker(svgMarker, { anchor: "bottom" })
  .setLngLat([longitude, latitude])
  .addTo(map);
